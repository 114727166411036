import {gql} from 'apollo-boost';

export const ME = gql`
  query {
    User {
      get {
        id
        email
        firstName
        lastName
        verifyCode
        verified
        company
        phone
        photo
        error
        promoData {
          hexowatch_ltd
        }
      }
    }
  }
`;

export const GENERATE_APOLLO_DATA = gql`
  query {
    User {
      generateApolloData {
        message
        error
      }
    }
  }
`;

//-------------Stripe/Payment--------------------------

export const LIST_CUSTOMER_CARD = gql`
  query($settings: ListCustomerCardsInput) {
    Billing {
      listCustomerCards(settings: $settings) {
        cards {
          id
          createdAt
          updatedAt
          cardId
          customerId
          brand
          lastDigits
          isDefault
        }
        error_code
      }
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query($settings: ListProductsInput) {
    Billing {
      listProducts(settings: $settings) {
        products {
          hexowatchProducts {
            id
            interval
            productId
            priceId
            unit_amount
            product_name
            max_frequency
            monthly_scans_limit
            isPopular
            description {
              proxy
              webhooks
              geo_checks
              device_type
              integrations
              actions
              sitemap_monitor
              api_key
            }
          }
        }
        error_code
      }
    }
  }
`;
export const GET_USER_WATCH_SETTINGS_QUERY = gql`
  query {
    UserWatchSettings {
      get {
        id
        pricing_package_interval
        pricing_package
        left_monthly
        paypal_subscription_id
        stripe_subscription_id
        pending_payment
        can_use_trial
        trial_packages
      }
    }
    User {
      get {
        email
        verifyCode
        verified
        firstName
        promoData {
          hexowatch_ltd
        }
      }
    }
  }
`;

export const GET_WATCH_FLASH_PROMO_DATA = gql`
  query {
    User {
      getWatchFlashPromoCronDate {
        cron_date_left
      }
    }
  }
`;

export const GET_ACCESS_TOKEN = gql`
  query ($settings: GetAccessTokenInput) {
    User {
      getAccessToken (settings: $settings) {
        error_code
        token
      }
    }
  }
`;

export const HEXOFY_USER = gql`
  query HEXOFY_USER {
    HexofyUser {
      getHexofyUser {
        hfUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }

          pending_payment
          error_code
          settings {
            storage
            checks
          }
        }
      }
    }
  }
`;

export const USER_PLANS_QUERY = gql`
  query USER_QUERY {
    User {
      getUserPlans {
        hexomatic {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexowatch {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexometer {
          pricing_package
          pricing_package_interval
          pending_payment
          
        }
        hexospark {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        error_code
      }
    }
  }`;